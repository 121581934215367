class WorkflowsServiceClass {
    public socket: any;
    private namespace: string;
    constructor(_socket,_namespace) {
        this.socket = _socket;
        this.namespace = _namespace;
    }
 
    deleteProjectsAndWorkflows(project) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('DELETEPROJECTSANDWORKFLOWS',project._id, function (err) {
                    if(err) { return reject(err); }
                    resolve("project deleted");
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    delete_workflow(id:string, collectionname:string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('DELETEWORKFLOW', id, collectionname, function (err) {
                    if(err) { return reject(err); }
                    resolve("workflow deleted");
                });

            } catch (err) {
                reject(err);
            }
        });
    }   
    onstopworkflow(robotid:string, actionid:string, data:any) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('ONSTOPWORKFLOW', robotid, actionid, data , function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    UpdateworkflowsRoles(item:any, collectionname:string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATEWORKFLOWSROLES', item, collectionname, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    invoke(wff_id:string, item:any) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('INVOKE', wff_id, item, function (err, id, data) {
                    if(err) { return reject(err); }
                    if(id) { }
                    resolve(data);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    invokeRobotService(user_id:string, workflow_id:string, data:any) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('INVOKEROBOT', user_id, workflow_id, data, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
}